<template>
  <div class="content-box-padding">
    <div class="sub-title">城市空气质量达标规划</div>
    <div class="line-text" style="margin-top: 20px">
      技术优势<br />
      基于本地气象数据、污染源清单数据，利用空气质量模型对区域进行不同级别的划分（核心区、严控区、管控区、延申区）；<br />
      针对点源、面源溯源结果，实现关停或按比例削减企业不同污染排放量；<br />
      拥有丰富的空气质量达标规划工作经验和专家团队资源。<br />
    </div>
    <img
      src="~assets\image\business\城市空气质量达标规划.png"
      alt="城市空气质量达标规划"
      style="margin: 50px auto 0px auto; width: 80%"
    />
  </div>
</template>
